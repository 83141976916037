import React from 'react'
import { SmallText } from '@konsys-ui-custom'
import { Container } from './styled'
import logo from '../../assets/logo-circle-new.png'
import { theme } from '../../styles/_variables'

export default () => (
  <Container>
    <img src={logo} style={{ width: 300 }} />
    <SmallText color={theme.color.gray50}>Version 1.4.5</SmallText>
  </Container>
)
